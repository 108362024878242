/* Website URL */

const { languageMapper } = require('@frontastic-engbers/lib/project.config');

const siteUrl =
  process.env.SITE_URL ||
  process.env.URL ||
  process.env.NEXT_PUBLIC_SITE_URL ||
  process.env.NEXT_PUBLIC_URL ||
  'https://www.engbers.com';

const alternateRefs = [];

for (const [key] of Object.entries(languageMapper)) {
  alternateRefs.push({
    href: `${siteUrl}/${key}`,
    hreflang: key,
  });
}
/* Site map generator configuration */

/** @type {import('next-sitemap').IConfig} */
const config = {
  siteUrl,
  exclude: [
    '/sitemap-static.xml',
    '/sitemap-categories.xml',
    '/sitemap-products.xml',
    '/verify',
    '/__preview',
    '/feeds/category-feed',
  ],
  generateRobotsTxt: true,
  robotsTxtOptions: {
    policies: [
      {
        userAgent: '*',
        allow: '/',
      },
      {
        userAgent: '*',
        disallow: '/api',
      },
      {
        userAgent: '*',
        disallow: ['/__preview', '/verify'],
      },
      {
        userAgent: '*',
        disallow: ['/mein-konto', '/checkout', '/cart'],
      },
      {
        userAgent: '*',
        disallow: ['/*_*.*'],
      },
    ],
    additionalSitemaps: [
      `${siteUrl}/sitemap-static.xml`,
      `${siteUrl}/sitemap-categories.xml`,
      `${siteUrl}/sitemap-products.xml`,
    ],
  },
  alternateRefs,
};

module.exports = config;
